



























































































































































































































































































































































@import '~@/assets/styles/helpers/_variables.scss';

.v-card { border-radius: 16px !important; }

.v-form {
  padding: 0px 16px 0px;
  border: 8px solid $colorPrimary;
  border-radius: 0px 0px 16px 16px !important;
}

.v-btn { text-transform: uppercase; }

.toolBar {
  background-color: $colorPrimary !important;
  border-radius: 16px !important;
  display: flex;
  justify-content: center;
  margin: 0px;
  color: white;

  &-text {
    font-weight: bold;
    text-transform: uppercase;
  }
}

.utilButton {
  color: $colorPrimaryDarken;
  border: 3px solid $colorPrimaryDarken;
  border-radius: 16px;


  &:hover {
    color: white;
    border: 3px solid white;
  }
}
